<template>
  <div
    class="modal fade"
    id="group"
    tabindex="-1"
    role="dialog"
    aria-labelledby="group"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <button type="button" class="x-cancel" @click="hideModal">X</button>
        <div class="modal-header pt-4 pb-2">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ groupForm.title }}
          </h5>
        </div>
        <div class="modal-body m-0 p-0">
          <div
            class="row justify-content-center"
            style="padding-right: 1em; padding-left: 1em">
            <div class="col-md-12">
              <el-form
                :model="inputFormData"
                :rules="rules"
                ref="ruleForm"
                @submit.prevent.native="checkEnter">
                <div class="form_input_container">
                  <el-form-item label="Group Name" prop="name">
                    <el-input
                      type="text"
                      placeholder="Enter group name"
                      autocomplete="false"
                      v-model="inputFormData.name"></el-input>
                  </el-form-item>
                </div>
                <span v-if="errors.length">
                  <Alert
                    v-for="(error, key) in errors"
                    :key="key"
                    :message="error"
                    :type="type" />
                </span>
                <Alert v-if="success" :message="success" type="alert-success" />
              </el-form>
            </div>
          </div>
          <div class="modal__footer__btns">
            <button class="cancel" @click="hideModal">Cancel</button>
            <el-button
              :loading="loading"
              type="primary"
              @click="createNewGroup('ruleForm')"
              >{{ groupForm.btnTitle }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import store from "@/state/store.js";
  let $ = require("jquery");
  export default {
    name: "create-new-group",
    props: {
      editFormData: {
        required: false,
      },
      createNewGroupContacts: {
        required: false,
      },
    },
    data() {
      return {
        errors: [],
        loading: false,
        success: "",
        type: "alert-danger",
        inputFormData: {
          name: "",
          description: "",
        },
        rules: {
          name: [
            {
              required: true,
              message: "Group name is required",
              trigger: "blur",
            },
          ],
        },
      };
    },
    computed: {
      groupForm() {
        return store.getters["uistate/groupFormData"];
      },
      groups() {
        return store.getters["groups/groups"];
      },
    },
    methods: {
      hideModal() {
        $("#group").modal("hide");
        this.inputFormData = {
          name: "",
          description: "",
        };
        this.$emit("hiddenModal");
      },
      createNewGroup(formName) {
        this.errors = "";
        this.loading = true;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            store
              .dispatch("groups/createGroup", this.inputFormData)
              .then((response) => {
                this.success = response.data.message;
                this.inputFormData = {
                  name: "",
                  description: "",
                };
                // Take 5 seconds then hide the success message
                setTimeout(() => {
                  this.success = "";
                }, 5000);
              })
              .catch((err) => {
                if (err.response?.data == undefined) {
                  this.errors = ["Network error try again"];
                }
                if (err.response && err.response.status === 404) {
                  this.errors = err.response.data.data.error;
                }
              })
              .finally(() => {
                this.loading = false;
                store.dispatch("groups/listGroups", 1);
              });
          } else {
            this.loading = false;
            return false;
          }
        });
      },
    },
  };
</script>

<style scoped>
  .modal__footer__btns {
    display: flex;
    margin: 2.5em 1em;
    justify-content: space-between;
  }

  .cancel {
    background: #f5f5f5;
    border-radius: 5px;
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    padding: 0.5em 2em;
    text-align: center;
    color: #9e9ea7;
    outline: none;
    border: none;
  }

  .form_container {
    display: flex;
    justify-content: center;
  }

  .x-cancel {
    border: none;
    width: 10%;
    position: relative;
    left: 90%;
    background-color: transparent;
  }
</style>
