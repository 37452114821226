<template>
  <div
    class="modal fade form-container"
    id="contact"
    tabindex="-1"
    role="dialog"
    aria-labelledby="contact"
    aria-hidden="true">
    <div
      class="modal-dialog modal-dialog-centered add-contact-main"
      role="document"
      ref="mainAddContact">
      <div
        class="modal-content d-flex align-items-center success-modal"
        v-if="success">
        <successMessage :message="success" />
        <button @click="hideModal" class="__close__success__modal">
          Close
        </button>
      </div>
      <div class="modal-content main-add-contact" v-if="!success">
        <div class="modal-header pt-4 pb-2">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ contactForm.title }}
          </h5>
        </div>
        <div class="modal-body m-0 p-0">
          <div
            class="row justify-content-center"
            style="padding-right: 1em; padding-left: 1em">
            <div class="col-md-12 form-main-use">
              <el-form
                :model="formData"
                :rules="rules"
                ref="ruleForm"
                @submit.prevent.native="checkEnter">
                <div class="row justify-content-center name-container">
                  <div class="col-md-6">
                    <div class="form_input_container">
                      <el-form-item label="First Name" prop="firstname">
                        <el-input
                          type="text"
                          autocomplete="off"
                          placeholder="First Name"
                          v-model="formData.firstname"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form_input_container">
                      <el-form-item label="Last Name" prop="lastname">
                        <el-input
                          type="text"
                          autocomplete="off"
                          placeholder="Last Name"
                          v-model="formData.lastname"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-md-12 my-0 py-0">
                    <el-form-item
                      style="margin: 0; padding: 0"
                      label="Birthday">
                    </el-form-item>
                  </div>
                  <div class="col-md-12 notranslate">
                    <div class="form_input_container mb-2 notranslate">
                      <el-form-item
                        style="width: 100%; margin: 0; padding: 0"
                        prop="date_of_birth"
                        class="notranslate">
                        <el-date-picker
                          v-model="formData.date_of_birth"
                          type="date"
                          style="width: 100%"
                          format="yyyy-MM-dd"
                          placeholder="1999-02-05"
                          class="notranslate"
                          popper-class="notranslate">
                        </el-date-picker>
                      </el-form-item>
                    </div>
                  </div>
                  <!-- <div class="col-4 col-sm-4 col-md-4">
                                            <div class="form_input_container">
                                                <el-form-item  prop="day">
                                                    <el-date-picker
                                                        style="width:100%;"
                                                        v-model="formData.day"
                                                        type="date"
                                                        format="dd"
                                                        placeholder="Day">
                                                    </el-date-picker>
                                                </el-form-item>
                                            </div>
                                        </div>
                                        <div class="col-4 col-sm-4 col-md-4">
                                        <div class="form_input_container">
                                                <el-form-item  prop="month">
                                                    <el-date-picker
                                                        style="width:100%"
                                                        v-model="formData.month"
                                                        type="month"
                                                        format="MM"
                                                        placeholder="Month">
                                                    </el-date-picker>
                                                </el-form-item>
                                            </div>
                                        </div>
                                        <div class="col-4 col-sm-4 col-md-4">
                                            <div class="form_input_container">
                                                <el-form-item  prop="year">
                                                    <el-date-picker
                                                        v-model="formData.year"
                                                        type="year"
                                                        style="width:100%"
                                                        format="yyyy"
                                                        placeholder="Year">
                                                    </el-date-picker>
                                                </el-form-item>
                                            </div>
                                        </div> -->
                </div>
                <div class="row justify-content-center">
                  <div class="col-md-12 name-container">
                    <div class="form_input_container">
                      <el-form-item label="Mobile Number" prop="phone">
                        <el-input
                          type="text"
                          autocomplete="off"
                          placeholder="Mobile Number"
                          v-model="formData.phone"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="col-md-12 name-container">
                    <div class="form_input_container">
                      <el-form-item label="Email" prop="email">
                        <el-input
                          type="email"
                          autocomplete="off"
                          placeholder="Email"
                          v-model="formData.email"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form_input_container contains-createGroup">
                      <div class="left-label">
                        <el-form-item label="Assign to group" prop="group_id">
                          <el-select
                            style="width: 100%"
                            v-model="formData.group_id"
                            filterable
                            placeholder="Choose a group">
                            <div
                              class="infinite-scroll"
                              v-infinite-scroll="useInfiniteLoading">
                              <el-option
                                v-for="(group, key) in foundGroups"
                                :key="key"
                                :label="group.name"
                                :value="group.id">
                              </el-option>
                              <small
                                class="loading-state"
                                v-if="loadingGroupMembers">
                                Loading...
                              </small>
                              <small
                                class="loading-state loading-success"
                                v-if="
                                  groups.length > 0 &&
                                  groups.length == totalGroupMembers
                                ">
                                All groups loaded successfully
                              </small>
                            </div>
                          </el-select>
                        </el-form-item>
                      </div>
                      <!-- Button To Create Group -->
                      <div class="right-button">
                        <el-button
                          @click="addNewGroup"
                          type="primary"
                          style="
                            width: 158px;
                            height: 38px;
                            border-radius: 5px;
                            font-weight: 600;
                            font-size: 14px;
                          ">
                          Create Group</el-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <span v-if="errors.length">
                  <Alert
                    v-for="(error, key) in errors"
                    :key="key"
                    :message="error[0]"
                    :type="type" />
                </span>
              </el-form>
            </div>
          </div>
          <div class="modal__footer__btns">
            <button class="cancel" @click="hideModal">Cancel</button>
            <el-button
              :loading="loading"
              id="__create__btn"
              @click="submit('ruleForm')">
              <img src="../../../assets/3 User.svg" alt="user icon" />&nbsp;
              {{ contactForm.btnTitle }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <groupModal
      ref="groupModal"
      class="is-on-top"
      @hiddenModal="removeOpacity" />
  </div>
</template>
<script>
  import store from "@/state/store.js";
  import formatInputDate from "@/helpers/formatInputDate";
  import groupModal from "./createNewGroupModal.vue";
  let $ = require("jquery");
  // TODO: VALIDATE PHONE NUMBER AS USED IN SIGN AND SIGNUP
  export default {
    name: "create-new-group",
    data() {
      return {
        success: "",
        errors: [],
        loading: false,
        type: "alert-danger",
        formData: {
          firstname: "",
          lastname: "",
          name: "",
          date_of_birth: "",
          group_id: "",
        },
        rules: {
          firstname: [
            {
              required: true,
              message: "Firstname is required.",
              trigger: "change",
            },
          ],
          lastname: [
            {
              required: false,
              message: "Lastname is required.",
              trigger: "change",
            },
          ],
          group_id: [
            { required: true, message: "Select a group.", trigger: "change" },
          ],
          // email: [
          //     {required: true, message: 'Please Enter email.', trigger: 'change' },
          //     { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
          // ],
          phone: [
            {
              required: true,
              message: "Phone is required.",
              trigger: "change",
            },
            {
              pattern:
                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/g,
              message: "Enter a valid phone number",
              trigger: "change",
            },
            {
              min: 10,
              max: 15,
              message: `Length should be greater than 10`,
              trigger: "blur",
            },
          ],
          // date_of_birth: [
          //     {
          //         required: true,message:'Date of birth is required.', trigger: 'change'
          //     }
          // ]
        },
        newGroupFromContacts: "",
        loadingGroupMembers: false,
        groups: [],
        page: 1,
        loadingGroupMembers: false,
        totalGroupMembers: 0,
      };
    },
    mounted() {
      return store.getters["groups/all_groups"];
    },
    created() {
      store
        .dispatch("groups/listGroups", this.page)
        .then((response) => {
          this.fetchingGroup = false;
          let data = response.data.data.data;
          this.totalGroupMembers = response.data.data.total;
          this.groups = data;
          this.page = response.data.data.current_page + 1;
        })
        .catch((err) => (this.fetchingGroup = false));
    },
    computed: {
      contactForm() {
        return store.getters["uistate/contactForm"];
      },
      foundGroups() {
        return this.groups;
      },
    },
    methods: {
      createNewContact() {
        if (this.title && this.title != "") {
        }
      },
      hideModal() {
        $("#contact").modal("hide");
        this.success = "";
        this.errors = "";
        this.formData = {
          firstname: "",
          lastname: "",
          name: "",
          date_of_birth: "",
          group_id: "",
        };
        // // Emit Done Adding Contact to parent for refresh
        // this.$emit('doneAddingContact')
      },
      submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.errors = "";
            this.loading = true;
            let { date_of_birth, email, phone, firstname, lastname, group_id } =
              this.formData;

            let date = formatInputDate(this.formData.date_of_birth);

            let payload = {
              firstname: firstname,
              lastname: lastname,
              date_of_birth: date,
              phone: phone,
              email: email,
              group_id: group_id,
            };
            store
              .dispatch("contacts/createContact", payload)
              .then((response) => {
                (this.success = response.data.message),
                  this.$emit("updateContact", response.data.data),
                  (this.formData = {
                    firstname: "",
                    lastname: "",
                    day: "",
                    month: "",
                    year: "",
                    name: "",
                    group_id: "",
                  });
                // Emit Done Adding Contact to parent for refresh
                this.$emit("doneAddingContact");
              })
              .catch((err) => {
                if (err.response.data == undefined) {
                  this.errors = ["Network error try again"];
                }
                if (err.response && err.response.status === 404) {
                  this.errors = err.response.data.data.error;
                }
              })
              .finally(() => (this.loading = false));
          } else {
            this.loading = false;
            return false;
          }
        });
      },
      addNewGroup() {
        store.dispatch("uistate/addGroupFormData", {
          title: "Create new group",
          mainModalType: "new",
          type: "new",
          btnTitle: "Create new group",
        });
        let element = this.$refs.groupModal.$el;
        $(element).modal("show");
        let myDiv = this.$refs.mainAddContact;
        myDiv.classList.toggle("is-fade");
      },
      removeOpacity() {
        let myDiv = this.$refs.mainAddContact;
        myDiv.classList.remove("is-fade");

        //  recall the groups
        store
          .dispatch("groups/listGroups", 1)
          .then((response) => {
            this.fetchingGroup = false;
            let data = response.data.data.data;
            this.totalGroupMembers = response.data.data.total;
            this.groups = data;
            this.page = response.data.data.current_page + 1;
          })
          .catch((err) => (this.fetchingGroup = false));
      },
      useInfiniteLoading() {
        if (this.loadingGroupMembers) {
          return;
        }

        this.loadingGroupMembers = true;
        if (this.groups.length === this.totalGroupMembers) {
          this.loadingGroupMembers = false;
          return;
        }
        // Increment this.page only if there is more data to load
        if (this.groups.length < this.totalGroupMembers) {
          store
            .dispatch("groups/listGroups", this.page)
            .then((response) => {
              let data = response.data.data.data;

              // Filter out duplicates before appending
              data = data.filter(
                (group) =>
                  !this.groups.find(
                    (existingGroup) => existingGroup.id === group.id
                  )
              );

              if (data.length > 0) {
                // Append filtered data to this.groups
                this.groups = [...this.groups, ...data];
              }

              // Increment this.page here
              this.page = response.data.data.current_page + 1;
            })
            .finally(() => {
              this.loadingGroupMembers = false;
            });
        }
      },
    },
    components: {
      groupModal,
    },
  };
</script>
<style scoped>
  .modal__footer__btns {
    display: flex;
    margin: 1.5em 1em;
    justify-content: space-between;
  }

  .cancel {
    background: #f5f5f5;
    border-radius: 5px;
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    padding: 0.5em 2em;
    text-align: center;
    color: #9e9ea7;
    outline: none;
    border: none;
  }

  .form_container {
    display: flex;
    justify-content: center;
  }

  .form-container {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    top: 1rem;
  }

  .form-container * {
    box-sizing: border-box;
    margin: 0 auto;
  }

  .modal__footer__btns {
    display: flex;
    margin: 2em 1em;
    justify-content: space-between;
  }

  .name-container {
    /* background-color: red; */
    padding-bottom: 1rem;
  }

  .success-modal {
    height: 35vh;
  }

  .is-on-top {
    box-shadow: inset 100vmin 100vmin 4px rgba(0, 0, 0, 0.7);
  }

  .add-contact-main {
    transition: all 0.5s ease;
    overflow-y: auto;
  }

  .contains-createGroup {
    display: flex;
    height: 100%;
  }

  .left-label {
    height: 100%;
  }

  .right-button {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }

  .main-add-contact {
    height: min(700px, 95vh);
    overflow-y: auto;
  }

  .main-add-contact::-webkit-scrollbar {
    width: 5px;
  }
  .main-add-contact::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .main-add-contact::-webkit-scrollbar-thumb {
    background: #c9c9c9;
  }

  .infinite-scroll {
    height: 150px;
    padding-top: 1rem;
    overflow-y: scroll;
  }

  .loading-state {
    color: #9e9ea7;
    padding-inline: 20px;
  }

  @media screen and (max-width: 450px) {
    .form-main-use {
      height: 50vh;
      overflow-y: scroll;
    }

    .contains-createGroup {
      display: block;
    }

    .right-button {
      align-items: flex-start;
    }
  }
</style>
